/*
 * Generated by orval v3.2.3 🍺
 * Do not edit manually.
 * BFF Customers Api
 * OpenAPI spec version: v6
 */

export type MessageCode =
  | 'CUSTOMER_FROZEN'
  | 'CUSTOMER_FROZEN_AWAITING_CORRECTION'
  | 'CUSTOMER_FROZEN_REFUSED_SAME_REGION'
  | 'CUSTOMER_FROZEN_REFUSED_OTHER_REGION'
  | 'CUSTOMER_FROZEN_AWAITING_REGION_VALIDATION'
  | 'CUSTOMER_FROZEN_DEAD'
  | 'CUSTOMER_FROZEN_MOVE_REGION'
  | 'CUSTOMER_FROZEN_MOVE_UNRESOLVED_LOCATION';

export const MessageCode = {
  CUSTOMER_FROZEN: 'CUSTOMER_FROZEN' as MessageCode,
  CUSTOMER_FROZEN_AWAITING_CORRECTION: 'CUSTOMER_FROZEN_AWAITING_CORRECTION' as MessageCode,
  CUSTOMER_FROZEN_REFUSED_SAME_REGION: 'CUSTOMER_FROZEN_REFUSED_SAME_REGION' as MessageCode,
  CUSTOMER_FROZEN_REFUSED_OTHER_REGION: 'CUSTOMER_FROZEN_REFUSED_OTHER_REGION' as MessageCode,
  CUSTOMER_FROZEN_AWAITING_REGION_VALIDATION: 'CUSTOMER_FROZEN_AWAITING_REGION_VALIDATION' as MessageCode,
  CUSTOMER_FROZEN_DEAD: 'CUSTOMER_FROZEN_DEAD' as MessageCode,
  CUSTOMER_FROZEN_MOVE_REGION: 'CUSTOMER_FROZEN_MOVE_REGION' as MessageCode,
  CUSTOMER_FROZEN_MOVE_UNRESOLVED_LOCATION: 'CUSTOMER_FROZEN_MOVE_UNRESOLVED_LOCATION' as MessageCode,
};
